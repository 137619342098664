export const fonts = {
  body: "'Chakra Petch', sans-serif",
  heading: "'Chakra Petch', sans-serif",
  mono: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
};

export const fontSizes = {
  "2xs": "0.625rem",
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.25rem",
  xl: "1.5rem",

  heading_xs: "20px",
  heading_sm: "24px",
  heading_lg: "30px",
  heading_2lg: "38px",
  heading_xl: "44px",
  heading_2xl: "56px",
  heading_3xl: "64px",

  para_xs: "10px",
  para_sm: "12px",
  para_2sm: "14px",
  para_default: "16px",
  para_lg: "20px",
  para_xl: "24px",

  btn_sm: "10px",
  btn_default: "12px",
  btn_lg: "16px",
};

export const lineHeights = {
  heading_xs: "27.2px",
  heading_sm: "32.65px",
  heading_lg: "48px",
  heading_2lg: "54px",
  heading_xl: "57.2px",
  heading_2xl: "72.8px",
  heading_3xl: "82px",

  para_xs: "13px",
  para_sm: "20px",
  para_2sm: "23px",
  para_default: "21.76px",
  para_lg: "27.2px",
  para_xl: "32.65px",

  btn_sm: "10px",
  btn_default: "12px",
  btn_lg: "16px",
};
