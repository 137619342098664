const black = {
  100: "#27272E",
  200: "#16192C",
  300: "#121212",
  900: "#000",
};

const gray = {
  50: "#FAFAFA",
  100: "#F7FAFC",
  200: "#EDF2F7",
  300: "#E2E8F0",
  400: "#CBD5E0",
  500: "#858585",
  600: "#718096",
  700: "#4A5568",
};

export const colors = {
  primary: "#006AA4",
  secondary: "#00BBDD",
  tertiary: "#31AA6B",
  brandYellow: "#FFC152",
  brandOrange: "#FE774C",
  state: {
    success: "#66CB9F",
    warning: "#F7936F",
    danger: "#F16063",
    info: "#2F80ED",
    dark: "#16192C",
  },
  black,
  gray,
  surface: {
    primary: "#FFFFFF",
    secondary: gray[100],
    light: "#F3F3F3",
    dark: black[300],
  },
};
