import { AccessToken, User } from "@/api/handlers/welcomePage";

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "accessToken";
const LOCAL_STORAGE_USER_KEY = "user";

interface AccessTokenExtended extends AccessToken {
  expiresAt: number;
}

const getAccessToken = (): AccessTokenExtended | null => {
  const userString = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

  if (!userString) {
    return null;
  }

  return JSON.parse(userString) as AccessTokenExtended;
};

export const getCachedToken = (): string | null => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return null;
  }

  return accessToken.token;
};

export const isAuthenticated = (): boolean => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return false;
  }

  return (
    !!accessToken.token &&
    !!accessToken.expiresAt &&
    Date.now() < accessToken.expiresAt
  );
};

export const setCachedToken = (accessToken: AccessToken) => {
  const accessTokenExtended = {
    ...accessToken,
    expiresAt: Date.now() + accessToken.expiresIn * 1000 * 60,
  };

  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, JSON.stringify(accessTokenExtended));
}

export const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
};

export const getCachedUser = (): User | null => {
  const userString = localStorage.getItem(LOCAL_STORAGE_USER_KEY);

  if (!userString) {
    return null;
  }

  return JSON.parse(userString);
};

export const setCachedUser = (userString: User) => {
  localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(userString));
};