import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Spinner, Text } from "@chakra-ui/react";

const LoadingSpinner = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
    >
      <Spinner
        size="xl"
        speed="0.65s"
        thickness="4px"
        emptyColor="gray.200"
        color="blue.500"
      />
      <Text fontSize="lg" fontWeight="medium" mt={4}>
        {t("loading")}
      </Text>
    </Box>
  );
};

export default LoadingSpinner;
