// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { breakpoints, globalStyles, spacing } from "./global-styles";
import { fonts, fontSizes, lineHeights } from "./typography";

const theme = extendTheme({
  colors,
  fonts,
  fontSizes,
  lineHeights,
  breakpoints,
  space: spacing,
  styles: {
    global: globalStyles,
  },
  sizes: { container: breakpoints },
});

export default theme;
