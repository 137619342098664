import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          loading: "Loading...",
          network: {
            unknown_error:
              "<0>Oops! Something went wrong.</0><1>An unknown error occurred. Please try again later.</1><2>Try Again</2>",
            not_found:
              "<0>Oops! Source Not Found.</0></1>The resource you're looking for doesn't exist.</1><2>Go back</2>",
            unauthorized:
              "<0>Oops! No Permission.</0><1>Sorry, it looks like you don't have permission to view this content. Please log in and try again.</1>",
            too_many_requests:
              "<0>Oh!Too many requests.</0><1>Please wait a moment and try again.</1>",
          },
          general: {
            unknown_error: "Something went wrong, please try again.",
          },
          student_dashboard: {
            user_panel: {
              my_profile: "My Profile",
              logout: "Logout",
            },
          },
          intervention_form: {
            initial_data: {
              title: "Student's Initial Data",
              button: "Next",
            },
            family_data: {
              title: "Family Data",
              button: "Next",
            },
            eco_env: {
              title: "ECONOMIC-ENVIRONMENTAL SITUATION",
              button: "Next",
            },
            fam_env_bg: {
              title: "FAMILY AND ENVIRONMENTAL BACKGROUND",
              button: "Next",
            },
            personal_history: {
              title: "PERSONAL HISTORY",
              button: "Next",
            },
            diseases_disorders: {
              title: "DISEASES OR DISORDERS",
              button: "Next",
            },
            general_behavior: {
              title: "GENERAL BEHAVIOR",
              button: "Next",
            },
            school_circumstances: {
              title: "SCHOOL CIRCUMSTANCES",
              button: "Next",
            },
            family_dynamics: {
              title: "FAMILY DYNAMICS",
              button: "Next",
            },
            aspects_dynamics: {
              title: "ASPECTS EVALUATED",
              button: "Next",
            },
            psychopedagogical_diagnosis: {
              title: "PSYCHOPEDAGOGICAL DIAGNOSIS",
              button: "Next",
            },
            conclusions: {
              title: "CONCLUSIONS",
              button: "Next",
            },
            recommendations: {
              title: "RECOMMENDATIONS",
              button: "Next",
            },
          },
          welcome_registration: {
            agreement:
              "By continuing, you agree to our <1>Terms of Service</1> and the <3>Privacy Policy</3>",
            title: "Registration",
            users: "Student",
            users_placeholder: "Student",
            firstName: "First Name",
            firstName_placeholder: "your first name",
            surname: "Surname",
            surname_placeholder: "Your surname",
            city: "City",
            city_placeholder: "Your city",
            sex: "Sex",
            sex_placeholder: "Your sex",
            address: "Address",
            address_placeholder: "Your address",
            email: "Email Address",
            email_placeholder: "Your email address",
            phone: "Telephone",
            phone_placeholder: "Your telephone",
            school_age: "At what age did you first go to school?",
            school_age_placeholder: "Select",
            schools: "How many schools have you been to?",
            schools_placeholder: "Schools",
            school_type: "School type?",
            school_type_placeholder: "School type",
            changed_schools:
              "Have you changed schools in the middle of the year?",
            changed_schools_placeholder: "Select",
            repeat_course: "Have you repeated any course?",
            repeat_course_placeholder: "Select",
            adaptation_problem: "Did you had adaptation problems?",
            adaptation_problem_placeholder: "Select",
            attendance: "Attendance",
            attendance_placeholder: "Select",
            performance: "Performance",
            performance_placeholder: "Select",
            why: "Why?",
            why_placeholder: "Why?",
            attend_class: "Do you attend class regularly?",
            attend_class_placeholder: "Select",
            other: "Other data",
            other_placeholder: "Other data",
            lastName: "Last Name",
            lastName_placeholder: "your last name",
            birthDate: "Date of Birth",
            birthdate: "Date of Birth",
            country: "Country/Region",
            country_placeholder: "select country/region",
            place_in_your_family: "Place in your family",
            place_in_your_family_placeholder: "Select",
            institutionId: "Institution",
            institutionId_placeholder: "select institution",
            institutionToken: "Token",
            institutionToken_placeholder: "enter token",
            submit_button: "Finish",
            firstName_invalid: "Invalid first name",
            lastName_invalid: "Invalid last name",
            birthDate_invalid: "Invalid birth date",
            empty_field: "This field is required",
            invalid_token: "Invalid token",
            user_registered: "Your email has been registered with this token",
            successful_heading: "Congratulations!",
            successful_message:
              "Your account has been successfully created. Enjoy all the amazing courses we have for you!",
            password_placeholder: "Your password",
            password: "Password",
            password_invalid: "Password must be at least 9 characters long",
            signup_failed: "Registration failed",

            father_name: "Father Name",
            father_name_placeholder: "Your Father Name",
            father_age: "Father Age",
            father_age_placeholder: "Your Father Age",
            father_studies: "Father Studies",
            father_studies_placeholder: "Your Father Studies",
            father_profession: "Father Profession",
            father_profession_placeholder: "Your Father Profession",

            mother_name: "Mother Name",
            mother_name_placeholder: "Your Mother Name",
            mother_age: "Mother Age",
            mother_age_placeholder: "Your Mother Age",
            mother_studies: "Mother Studies",
            mother_studies_placeholder: "Your Mother Studies",
            mother_profession: "Mother Profession",
            mother_profession_placeholder: "Your Mother Profession",

            other_family: "Other Family",
            other_family_placeholder: "Your Other Family",
            tutors: "Tutors",
            tutors_placeholder: "Your Tutors",

            // 3. Eco ENV Form
            // 3.a Family Income
            family_income: {
              father: "Father",
              father_placeholder: "Your Father",
              mother: "Mother",
              mother_placeholder: "Your Mother",
              children: "Children",
              children_placeholder: "Your Children",
              other_data_orphanhood: "Other data: Orphanhood",
              other_data_orphanhood_placeholder: "Other data: Orphanhood",
              widowhood: "Widowhood",
              widowhood_placeholder: "Widowhood",
              retirement: "Retirement",
              retirement_placeholder: "Retirement",
              deceases: "Deceases",
              deceases_placeholder: "Deceases",
              family_member: "Family Member",
              family_member_placeholder: "Family Member",
              disability: "Disability",
              disability_placeholder: "Disability",
              observations: "Observations",
              observations_placeholder: "Observations",
            },

            // 3.b Living place
            living_place: {
              owned: "Owned",
              owned_placeholder: "Owned",
              rented: "Rented",
              rented_placeholder: "Rented",
              amortization: "Amortization",
              amortization_placeholder: "Amortization",
              number_of_rooms: "Number of rooms",
              number_of_rooms_placeholder: "Number of rooms",
              ventilation: "Ventilation",
              ventilation_placeholder: "Ventilation",
              humidity: "Humidity",
              humidity_placeholder: "Humidity",
              services_hot_water: "Services hot water",
              services_hot_water_placeholder: "Services hot water",
              toilet: "Toilet",
              toilet_placeholder: "Toilet",
              bathroom: "Bathroom",
              bathroom_placeholder: "Bathroom",
              heating: "Heating",
              heating_placeholder: "Heating",
              others: "Others",
              others_placeholder: "Others",
              living_place_observations: "Observations",
              living_place_observations_placeholder: "Observations",
            },

            // 3.c School situation that involves
            school_situation: {
              school_transportation: "Do you use school transportation?",
              school_transportation_placeholder:
                "Do you use school transportation?",
              school_cafeteria: "Do you use a school cafeteria?",
              school_cafeteria_placeholder: "Do you use a school cafeteria?",
              time_attend_class:
                "What time should the student get up to attend class?",
              time_attend_class_placeholder:
                "What time should the student get up to attend class?",
              time_spent_outside_home_on_school_day:
                "How many hours does the student spend outside the home on a school day?",
              time_spent_outside_home_on_school_day_placeholder:
                "How many hours does the student spend outside the home on a school day?",
              other: "Other data",
              other_placeholder: "Other data",
            },

            // 4. Family and environmental background
            // 4.a Relatives
            relatives: {
              family_ancestor_problem:
                "Does any family member or ancestor have the same problem?:",
              family_ancestor_problem_placeholder:
                "Does any family member or ancestor have the same problem?:",
              type_of_disabilities:
                "People with disabilities in the family, what type?:",
              type_of_disabilities_placeholder:
                "People with disabilities in the family, what type?:",
              psychosocial_disabilities:
                "People with psychosocial disabilities in the familiy. What type?:",
              psychosocial_disabilities_placeholder:
                "People with psychosocial disabilities in the familiy. What type?:",
              other_problems: "Other Problems",
              other_problems_placeholder: "Other Problems",
              are_the_parents_related: "Are the parents related?",
              are_the_parents_related_placeholder: "Are the parents related?",
            },
            // 4.b Environmental
            environmental: {
              native_language: "Native language",
              native_language_placeholder: "Your Native language",
              other_languages: "Other Languages",
              other_languages_placeholder: "Your other languages",
              child_residence_change:
                "Has the child undergone changes of residence?",
              child_residence_change_placeholder:
                "Has the child undergone changes of residence?",
              when: "When?",
              when_placeholder: "When?",
              why: "Why ?",
              why_placeholder: "Why ?",
              social_position_change:
                "Have there been sudden changes in social position?",
              social_position_change_placeholder:
                "Have there been sudden changes in social position?",
              child_lived_outside: "Has the child lived outside the family?",
              child_lived_outside_placeholder:
                "Has the child lived outside the family?",
              child_lived_outside_why:
                "Has the child lived outside the family?",
              child_lived_outside_why_placeholder:
                "Has the child lived outside the family?",
              with_whom: "With Whom?",
              with_whom_placeholder: "With Whom?",
              how_long: "How Long?",
              how_long_placeholder: "How Long?",
              what_age: "At what age?",
              what_age_placeholder: "At what age?",
              child_sleep_where: "Where does the child sleep?",
              child_sleep_where_placeholder: "Where does the child sleep?",
              alone: "Alone?",
              alone_placeholder: "Alone?",
              with_siblings: "With other siblings?",
              with_siblings_placeholder: "With other siblings?",
              with_parents: "With parents?",
              with_parents_placeholder: "With parents?",
              others: "Others",
              others_placeholder: "Others",
            },

            // 5. Personal History
            // 5.a Prenatal - Pregnancy
            prenatal_pregnancy: {
              wanted_pregnancy: "Was it a wanted pregnancy?",
              wanted_pregnancy_placeholder: "Was it a wanted pregnancy?",
              family_reaction_pregnancy:
                "How did the family react to the pregnancy?",
              family_reaction_pregnancy_placeholder:
                "How did the family react to the pregnancy?",
              medical_supervision_pregnancy:
                "Was the pregnancy under medical supervision?",
              medical_supervision_pregnancy_placeholder:
                "Was the pregnancy under medical supervision?",
              medical_problems_pregnancy:
                "During pregnancy did you have any medical problems?",
              medical_problems_pregnancy_placeholder:
                "During pregnancy did you have any medical problems?",
              which: "Which ?",
              which_placeholder: "Which ?",
              when: "When ?",
              when_placeholder: "When ?",
            },
            // 5.b Perinatal circumstances-childbirth
            perinatal_circumstances_childbirth: {
              child_birth_preparation:
                "Was the mother prepared for childbirth ?",
              child_birth_preparation_placeholder:
                "Was the mother prepared for childbirth ?",
              delivery_length:
                "How long did it take from the first contractions to delivery ?",
              delivery_length_placeholder:
                "How long did it take from the first contractions to delivery ?",
              delivery_location: "Delivery location",
              delivery_location_placeholder: "Delivery location",
              normal_or_induced_birth: "Was the birth normal or induced ?",
              normal_or_induced_birth_placeholder:
                "Was the birth normal or induced ?",
              induced_how: "If it was induced, how ?",
              induced_how_placeholder: "If it was induced, how ?",
              gestation: "Premature: months of gestation",
              gestation_placeholder: "Premature: months of gestation",
              thermo_cradle: "Thermo cradle",
              thermo_cradle_placeholder: "Thermo cradle",
              child_delivery_problems:
                "Did the child have any problems during or immediately after delivery?",
              child_delivery_problems_placeholder:
                "Did the child have any problems during or immediately after delivery?",
              which: "Which ?",
              which_placeholder: "Which ?",
              mother_delivery_problems:
                "Did the mother have any problems during or immediately after delivery?",
              mother_delivery_problems_placeholder:
                "Did the mother have any problems during or immediately after delivery?",
              mother_delivery_problems_which: "Which ?",
              mother_delivery_problems_which_placeholder: "Which ?",
              malformation:
                "Was the child born with any congenital defect or malformation ?",
              malformation_placeholder:
                "Was the child born with any congenital defect or malformation ?",
              other: "Other data",
              other_placeholder: "Other data",
            },
            // 5.c Psychomotor development:
            psychomotor_development: {
              baby_hold_head: "When did the baby hold his head?",
              baby_hold_head_placeholder: "When did the baby hold his head?",
              social_smile: "When did the social smile appear?",
              social_smile_placeholder: "When did the social smile appear?",
              baby_seated: "When did the baby stay seated?",
              baby_seated_placeholder: "When did the baby stay seated?",
              baby_crawl: "When did the baby start crawling ?",
              baby_crawl_placeholder: "When did the baby start crawling ?",
              baby_stand: "When did the baby stand ?",
              baby_stand_placeholder: "When did the baby stand ?",
              baby_walk: "When did the baby start walking?",
              baby_walk_placeholder: "When did the baby start walking?",
            },
            // 5.d Sphincter control:
            sphincter_control: {
              baby_pee: "When did the baby start asking for “ pee”?",
              baby_pee_placeholder:
                "When did the baby start asking for “ pee”?",
              baby_poop: "When did the baby start asking for “ poop”?",
              baby_poop_placeholder:
                "When did the baby start asking for “ poop”?",
              stop_control: "Did you stop controlling at any point?",
              stop_control_placeholder:
                "Did you stop controlling at any point?",
              baby_enuresis:
                "Did your baby had daytime and nighttime enuresis?",
              baby_enuresis_placeholder:
                "Did your baby had daytime and nighttime enuresis?",
              when_when: "From when to when?",
              when_when_placeholder: "From when to when?",
              baby_encopresis:
                "Did your baby had daytime or nocturnal encopresis?",
              baby_encopresis_placeholder:
                "Did your baby had daytime or nocturnal encopresis?",
              baby_encopresis_when_when: "From when to when ?",
              baby_encopresis_when_when_placeholder: "From when to when ?",
            },
            // 5.e Childhood:
            childhood: {
              childhood: "How was your child like when he/she was little ?",
              childhood_placeholder:
                "How was your child like when he/she was little ?",
            },

            // Diseases or disorders
            childhood_illnesses: "Childhood illnesses",
            childhood_illnesses_placeholder: "Childhood illnesses",
            others: "Others",
            others_placeholder: "Others",
            long_term_medication: "Have your baby had long-term medication?",
            long_term_medication_placeholder:
              "Have your baby had long-term medication?",
            hospitalizations: "Surgical interventions and hospitalizations",
            hospitalizations_placeholder:
              "Surgical interventions and hospitalizations",

            // 7. General Behavior
            // 7.a personal autonomy
            personal_autonomy: {
              meal: "Meal",
              meal_placeholder: "Meal",
              cleanliness: "Cleanliness",
              cleanliness_placeholder: "Cleanliness",
              dress: "Dress",
              dress_placeholder: "Dress",
              friends_lonely: "Does he/she have friends or is it lonely?",
              friends_lonely_placeholder:
                "Does he/she have friends or is it lonely?",
              free_time: "What does he/she do in their free time?",
              free_time_placeholder: "What does he/she do in their free time?",
              what_games: "What games does he/she like?",
              what_games_placeholder: "What games does he/she like?",
              attention: "Does he/she pay attention to what is around?",
              attention_placeholder:
                "Does he/she pay attention to what is around?",
              alone_neighborhood:
                "Is he/she allowed to move around the neighborhood alone?",
              alone_neighborhood_placeholder:
                "Is he/she allowed to move around the neighborhood alone?",
              why: "Why ?",
              why_placeholder: "Why ?",
            },
            // 7.b Problematic behaviors
            problematic_behavior: {
              aggressive_behaviors:
                "Does he/she have aggressive behaviors? Explain",
              aggressive_behaviors_placeholder:
                "Does he/she have aggressive behaviors? Explain",
              throwing_tantrums: "Is he/she prone throwing tantrums? Explain",
              throwing_tantrums_placeholder:
                "Is he/she prone throwing tantrums? Explain",
              bother_annoy: "Does he/she like to bother and annoy others?",
              bother_annoy_placeholder:
                "Does he/she like to bother and annoy others?",
              hyperactive: "Is he/she a hyperactive child? Explain",
              hyperactive_placeholder: "Is he/she a hyperactive child? Explain",
              self_aggressive: "Does he/she have self-aggressive behaviors?",
              self_aggressive_placeholder:
                "Does he/she have self-aggressive behaviors?",
              stealing: "Does he/she like to take what is not theirs?",
              stealing_placeholder:
                "Does he/she like to take what is not theirs?",
              liar: "Is he/she a liar?",
              liar_placeholder: "Is he/she a liar?",
              home_runaway: "Has he/she ever run away from home?",
              home_runaway_placeholder: "Has he/she ever run away from home?",
              other_problematic: "Other problematic behaviors",
              other_problematic_placeholder: "Other problematic behaviors",
              food_difficulties:
                "Does he/she have difficulties with food? What type?",
              food_difficulties_placeholder:
                "Does he/she have difficulties with food? What type?",
              sleeping_trouble: "Does he/she have trouble sleeping? Explain",
              sleeping_trouble_placeholder:
                "Does he/she have trouble sleeping? Explain",
              unjustified_fears: "Does he/she have unjustified fears? Explain",
              unjustified_fears_placeholder:
                "Does he/she have unjustified fears? Explain",
              tics: "Does he/she have any tics? Explain",
              tics_placeholder: "Does he/she have any tics? Explain",
            },

            // 8. School Circumstances
            parent_teacher_relationship:
              "Do parents have relationships with teachers during the course?",
            parent_teacher_relationship_placeholder:
              "Do parents have relationships with teachers during the course?",
            how_many_times: "How many times?",
            how_many_times_placeholder: "How many times?",
            child_attendance: "Does the child attend class regularly?",
            child_attendance_placeholder:
              "Does the child attend class regularly?",
            school_likeness: "Do he/she like school?",
            school_likeness_placeholder: "Do he/she like school?",
            thought_about_teachers: "What does he/she think of their teachers?",
            thought_about_teachers_placeholder:
              "What does he/she think of their teachers?",
            home_study: "Does the child study at home?",
            home_study_placeholder: "Does the child study at home?",
            home_appropriate_place:
              "Does it have an appropriate place at home?",
            home_appropriate_place_placeholder:
              "Does it have an appropriate place at home?",
            respect_for_studying: "Is he/she respected when is studying?",
            respect_for_studying_placeholder:
              "Is he/she respected when is studying?",
            homework_intensity:
              "Does he/she think they have a lot of schoolwork?",
            homework_intensity_placeholder:
              "Does he/she think they have a lot of schoolwork?",
            homework: "Does he/she makes homework?",
            homework_placeholder: "Does he/she makes homework?",
            homework_time: "How much time does it take to complete homework ?",
            homework_time_placeholder:
              "How much time does it take to complete homework ?",
            homework_help: "Does anyone help he/she? Who?",
            homework_help_placeholder: "Does anyone help he/she? Who?",
            exam_reaction: "How does he/she react to an exam?",
            exam_reaction_placeholder: "How does he/she react to an exam?",
            home_reading: "Does he/she read at home?: What type of readings?",
            home_reading_placeholder:
              "Does he/she read at home?: What type of readings?",
            // home_reading_type: "Other problematic behaviors",
            // home_reading_type_placeholder: "Other problematic behaviors",
            parent_child_consideration:
              "How do parents consider their child to be like?",
            parent_child_consideration_placeholder:
              "How do parents consider their child to be like?",
            intelligence: "Intelligence",
            intelligence_placeholder: "Intelligence",
            child_attention: "Attention",
            child_attention_placeholder: "Attention",
            vocabulary_level: "Vocabulary level",
            vocabulary_level_placeholder: "Vocabulary level",
            imagination: "Imagination",
            imagination_placeholder: "Imagination",

            // 9. Family Dynamics
            family_problems: "Family problems that may affect the child",
            family_problems_placeholder:
              "Family problems that may affect the child",
            parent_child_relationship: "Relationship of parents with children",
            parent_child_relationship_placeholder:
              "Relationship of parents with children",
            parent_attitude: "Parents' attitude towards their child",
            parent_attitude_placeholder:
              "Parents' attitude towards their child",
            siblings_relationship:
              "Relationship between siblings and their participation in the child's games",
            siblings_relationship_placeholder:
              "Relationship between siblings and their participation in the child's games",
            parents_expectation_level:
              "Parents' level of expectations towards this child",
            parents_expectation_level_placeholder:
              "Parents' level of expectations towards this child",
            family_integration: "Child's level of family integration",
            family_integration_placeholder:
              "Child's level of family integration",

            // 10. Aspects evaluated
            learning_styles: "LEARNING STYLES",
            learning_styles_placeholder: "LEARNING STYLES",
            reading_comprehension: "READING COMPREHENSION",
            reading_comprehension_placeholder: "READING COMPREHENSION",
            reading_speed: "READING SPEED",
            reading_speed_placeholder: "READING SPEED",
            motor_area: "MOTOR AREA",
            motor_area_placeholder: "MOTOR AREA",
            mathematical_logic: "MATHEMATICAL LOGIC",
            mathematical_logic_placeholder: "MATHEMATICAL LOGIC",
            social_interaction: "SOCIAL INTERACTION",
            social_interaction_placeholder: "SOCIAL INTERACTION",
            higher_nervous_functions: "HIGHER NERVOUS FUNCTIONS",
            higher_nervous_functions_placeholder: "HIGHER NERVOUS FUNCTIONS",
            language: "LANGUAGE",
            language_placeholder: "LANGUAGE",
            daily_life_skills: "DAILY LIFE SKILLS",
            daily_life_skills_placeholder: "DAILY LIFE SKILLS",

            // 11. PSYCHOPEDAGOGICAL DIAGNOSIS
            psychopedagogical_diagnosis: "PSYCHOPEDAGOGICAL DIAGNOSIS",
            psychopedagogical_diagnosis_placeholder:
              "PSYCHOPEDAGOGICAL DIAGNOSIS",

            // 12. CONCLUSIONS
            conclusions: "CONCLUSIONS",
            conclusions_placeholder: "CONCLUSIONS",

            // 13. RECOMMENDATIONS
            recommendations: "RECOMMENDATIONS",
            recommendations_placeholder: "RECOMMENDATIONS",

            book_now_student_name: "STUDENT NAME",
            book_now_student_name_placeholder: "STUDENT NAME",
            book_now_phone_number: "PHONE NUMBER",
            book_now_phone_number_placeholder: "PHONE NUMBER",
            book_now_email_address: "EMAIL ADDRESS",
            book_now_email_address_placeholder: "EMAIL ADDRESS",

            welcome_form: {
              google_login_error: "Something went wrong, Please try again.",
              heading: "Welcome",
              email_code: {
                error:
                  "You have provided invalid code max number of times, please try again ", // This error will come only when all code trial has been done
              },
              email: {
                label: "Email Address",
                placeholder: "type your email",
                error: "Provide a valid email address",
                required: "Email Address is required",
              },
              password: {
                label: "Password",
                placeholder: "type your password",
                required: "Password is required",
                length_error: "Password must be at least 9 characters long",
              },
              login_button: "Login",
              signup_button: "Sign Up",
              login_failed: "Login failed",
              login_code_sent:
                "We sent you a temporary login code.\nPlease check your inbox",
              signup_code_sent:
                "We sent you a temporary sign up code.\nPlease check your inbox and paste the code below",
              login_code: {
                label: "Login code",
                placeholder: "paste login code",
                required: "Login Code Required",
                error: "Uh oh! your login code was incorrect. Please try again",
                incorrect: "Incorrect login code",
              },
              signup_code: {
                label: "Sign up code",
                placeholder: "paste sign up code",
                required: "Signup Code Required",
                error:
                  "Uh oh! your sign up code was incorrect. Please try again",
                incorrect: "Incorrect Signup code",
              },
              google_signup: "Continue with Google",
              continue_button: {
                email: "Continue with email",
                login: "Continue with login code",
                signup: "Create new account",
              },
              or: "or",
            },
          },
          landing_page: {
            menu: {
              home: "Home",
              about: "About",
              contact: "Contact Us",
              begin: "Begin",
              logout: "Logout",
            },
            review_section: {
              user: {
                review:
                  "The testimony goes here the testimony goes here the testimony goes here the testimony goes",
                designation: "School Teacher",
                name: "Javier Camilo",
              },
              heading: "What our users Say",
            },
            testimonials: [
              {
                review:
                  "Quinde LAB App provides access to great educational resources, enables interactive learning, and promotes self-paced learning.",
                designation: "Parent",
                name: "Melissa",
              },
              {
                review:
                  "After using Quinde LAB App, I noticed a significant improvement in student engagement and performance. It's a valuable tool for enhancing the learning experience.",
                designation: "Teacher",
                name: "Jhon",
              },
              {
                review:
                  "Quinde LAB App has a user-friendly interface, comprehensive resources, personalized feedback and engaging content.",
                designation: "Parent",
                name: "Brian",
              },
            ],
            info_cards_section: [
              {
                title: "Practical Approach to Learning",
                description:
                  "Quiz sections are interactive with drag and drop, multiple-choice, true/false options.",
              },
              {
                title: "Interactive Courses",
                description:
                  "Engaging visual/audio format for easy and quick understanding.",
              },
              {
                title: "Easy-to-Follow Lessons",
                description:
                  "Step by step instructions with proven methodologies to facilitate your learning.",
              },
            ],
            posterLG: {
              title:
                "<0>Quinde Lab</0> is your Door To a new World of <2>learning</2>",
              paragraph:
                "Embark on an exciting adventure at Quinde Lab, where fun and learning come together! Our platform is filled with a variety of engaging courses and educational resources, designed for curious minds.",
              button_left: "Join Now",
              button_right: "Watch how it works",
            },
            banner1: {
              title: "What is <1>Quinde Lab?</1>",
              paragraph:
                "Quinde Lab is a platform that allows kids access online education. They can access course materials online; take quizzes, track learning progress, etc all in one place.",
            },
            posterMD1: {
              title: "Fun and Effective, <1>Learning</1>, For Kids",
              paragraph:
                "Get a personalized experience. Our lessons keep students engaged through playful characters, and use different strategies to solidify their knowledge.",
              button: "Start Learning",
            },
            posterMD2: {
              title: "Give <1>Knowledge</1> to Students Through Play",
              paragraph:
                "With Quinde Lab, you can easily customize the lesson's format for students. Create and upload lessons on topics that will engage the students and sharpen their skills.",
              button: "Start Teaching",
            },
            footer: {
              privacy: "Privacy Policy",
              terms: "Terms",
              faqs: "FAQs",
              contact: "Contact US",
              copyright: "© {{year}} Quinde Lab",
            },
            start_delivery_section: {
              heading:
                "Start delivering learning that impacts the younger generation today",
              btn_text: "Get Started",
            },
          },
          contact_us: {
            banner: {
              title: "Contact <1>Us</1>",
              paragraph:
                "Do you have any question in mind? Just write us a message",
            },
            form: {
              first_name: {
                label: "First Name",
                placeholder: "your first name",
                error: {
                  required: "This field is required",
                },
              },
              last_name: {
                label: "Last Name",
                placeholder: "your last name",
                error: {
                  required: "This field is required",
                },
              },
              email: {
                label: "Email Address",
                placeholder: "email address here",
                error: {
                  required: "Email is required!",
                  invalid: "Provide a valid email address",
                },
              },
              phone: {
                label: "Phone Number [optional]",
                placeholder: "[555] 000 000",
                select: {
                  label: "Search for country",
                },
              },
              subject: {
                label: "Select Subject?",
                options: {
                  general_query: "General Inquiry",
                  quinde_lab_app: "Quinde LAB app",
                  others: "Others",
                },
              },
              message: {
                label: "Message",
                placeholder: "write your message here",
                error: {
                  required: "Message field can’t be empty",
                },
              },
              action_btn: {
                text: "Send Message",
              },
            },
            info: {
              contact_info: "Contact Information",
              get_in_touch: "Get in touch!",
              phone: "+593 96345 7531",
              email: "info@quinde.org",
              address: "Quito, Ecuador",
            },
            modal: {
              success_heading_msg: "Your message was sent successfully",
              secondary_message:
                "Our team will respond to you as soon as possible. Thank you!",
            },
          },
          dashboard: {
            sidebar: {
              home: "Home",
              courses: "Courses",
              bookmarks: "Bookmarks",
              messages: "Messages",
              intervention: "Forms",
            },
            profile: {
              my_profile: "My Profile",
              account_info: "Account Information",
              logout: "Logout",
            },
            course: {
              type: "Course",
              module: "Module",
              description: "Description coming soon",
            },
          },
        },
      },
      es: {
        translation: {
          loading: "Cargando...",
          network: {
            unknown_error:
              "<0>¡Ups! Algo salió mal.</0><1>Se produjo un error desconocido. Por favor, inténtalo de nuevo más tarde.</1><2>Intenta de nuevo</2>",
            not_found:
              "<0>¡Vaya! Fuente no encontrada.</0><1>El recurso que estás buscando no existe.</1><2>Regresa</2>",
            unauthorized:
              "<0>¡Vaya! Sin permiso.</0><1>Lo siento, parece que no tienes permiso para ver este contenido. Por favor, inicia sesión e intenta de nuevo.</1>",
            too_many_requests:
              "<0>¡Oh! Demasiadas solicitudes.</0><1>Por favor, espera un momento e intenta nuevamente.</1>",
          },
          general: {
            unknown_error: "Algo salió mal. Por favor, vuelva a intentarlo.",
          },
          student_dashboard: {
            user_panel: {
              my_profile: "Mi Perfil",
              logout: "Cerrar sesión",
            },
          },
          welcome_registration: {
            agreement:
              "Al continuar, aceptas nuestros <1>Términos de servicio</1> y la <3>Política de privacidad</3>",
            title: "Registro",
            firstName: "Nombre",
            firstName_placeholder: "tu nombre",
            lastName: "Apellido",
            lastName_placeholder: "tu apellido",
            birthDate: "Fecha de nacimiento",
            country: "País/Región",
            country_placeholder: "selecciona país/región",
            institutionId: "Institución",
            institutionId_placeholder: "selecciona institución",
            institutionToken: "Token",
            institutionToken_placeholder: "ingresa el token",
            submit_button: "Terminar",
            firstName_invalid: "Nombre inválido",
            lastName_invalid: "Apellido inválido",
            birthDate_invalid: "Fecha de nacimiento inválida",
            empty_field: "Este campo es obligatorio",
            invalid_token: "Token inválido",
            user_registered:
              "Su correo electrónico ha sido registrado con este token",
            successful_heading: "¡Felicitaciones!",
            successful_message:
              "Tu cuenta ha sido creada exitosamente. ¡Disfruta de todos los cursos increíbles que tenemos para ti!",
            password_placeholder: "Tu contraseña",
            password: "Contraseña",
            password_invalid: "La contraseña debe tener al menos 9 caracteres",
            signup_failed: "Registro fallido",
            welcome_form: {
              heading: "Bienvenido",
              google_login_error:
                "Algo salió mal. Por favor, vuelva a intentarlo.",
              email_code: {
                error:
                  "Ha proporcionado un número máximo de códigos no válidos, inténtelo de nuevo.", // This error will come only when all code trial has been done
              },
              email: {
                label: "Dirección de correo electrónico",
                placeholder: "escriba su correo electrónico",
                error: "Proporcione una dirección de correo electrónico válida",
                required: "Se requiere Dirección de correo electrónico",
              },
              password: {
                label: "Contraseña",
                placeholder: "escribe tu contraseña",
                required: "se requiere contraseña",
                length_error: "La contraseña debe tener al menos 9 caracteres",
              },
              login_button: "Acceso",
              signup_button: "Inscribirse",
              login_failed: "error de inicio de sesion",
              login_code_sent:
                "Le enviamos un código de inicio de sesión temporal.\nPor favor revise su bandeja de entrada",
              signup_code_sent:
                "Te enviamos un código de registro temporal.\nPor favor revise su bandeja de entrada y pegue el código a continuación",
              login_code: {
                label: "Código de inicio de sesión",
                placeholder: "pegar código de inicio de sesión",
                required: "Código de inicio de sesión requerido",
                error:
                  "¡UH oh! su código de inicio de sesión era incorrecto. Inténtalo de nuevo",
                incorrect: "Código de inicio de sesión incorrecto",
              },
              signup_code: {
                label: "Código de registro",
                placeholder: "pegar el código de registro",
                required: "Código de registro requerido",
                error:
                  "¡UH oh! su código de registro era incorrecto. Inténtalo de nuevo",
                incorrect: "Código de registro incorrecto",
              },
              google_signup: "Continuar con Google",
              continue_button: {
                email: "Continuar con el correo electrónico",
                login: "Continuar con el código de inicio de sesión",
                signup: "Crear una nueva cuenta",
              },
              or: "o",
            },
          },
          landing_page: {
            menu: {
              home: "",
              about: "Quiénes Somos?",
              contact: "Contacto",
              begin: "Empecemos",
              logout: "",
            },
            review_section: {
              user: {
                review:
                  "The testimony goes here the testimony goes here the testimony goes here the testimony goes",
                designation: "School Teacher",
                name: "Javier Camilo",
              },
              heading: "Qué dicen nuestros usuarios?",
            },
            testimonials: [
              {
                review:
                  "La aplicación Quinde LAB me brinda acceso a increíbles recursos educativos, permite el aprendizaje interactivo y promueve el estudio a mi propio ritmo.",
                designation: "Padre de Familia",
                name: "Melissa",
              },
              {
                review:
                  "Después de usar la aplicación Quinde LAB, noté una mejora significativa en la participación y el rendimiento de los estudiantes. Es una herramienta valiosa para mejorar la experiencia de aprendizaje.",
                designation: "Profesor",
                name: "Jhon",
              },
              {
                review:
                  "La aplicación Quinde LAB tiene una interfaz fácil de usar, recursos integrales, mentoría personalizada y un contenido atractivo.",
                designation: "Padre de Familia",
                name: "Brian",
              },
            ],
            info_cards_section: [
              {
                title: "Enfoque práctico para el aprendizaje",
                description:
                  "Las secciones de validación de conocimiento son interactivas con opciones de selección múltiple, verdadero o falso, listados, etc.",
              },
              {
                title: "Cursos Interactivos",
                description:
                  "Formato atractivo de video-audio para una comprensión fácil y rápida.",
              },
              {
                title: "Lecciones fáciles de seguir",
                description:
                  "Instrucciones paso a paso con metodologías probadas paara facilitar tu aprendizaje",
              },
            ],
            posterLG: {
              title:
                "<0>Quinde Lab</0> es tu Puerta a un nuevo Mundo de <2>aprendizaje</2>",
              paragraph:
                "Embárcate en una emocionante aventura en Quinde Lab, ¡donde la diversión y el aprendizaje se unen! Nuestra plataforma está llena de una variedad de cursos atractivos y recursos educativos, diseñados para mentes curiosas.",
              button_left: "Únete ahora",
              button_right: "Mira cómo funciona",
            },
            footer: {
              privacy: "Política de privacidad",
              terms: "Términos y condiciones",
              faqs: "Preguntas frecuentes",
              contact: "Contáctenos",
              copyright: "© {{year}} Quinde Lab",
            },
            banner1: {
              title: "¿Qué es <1>Quinde Lab?</1>",
              paragraph:
                "Quinde Lab es una plataforma que permite a los niños acceder a la educación en línea. Pueden acceder a los materiales del curso en línea, tomar cuestionarios, hacer un seguimiento del progreso del aprendizaje, etc., todo en un solo lugar.",
            },
            posterMD1: {
              title: "Divertido y efectivo, <1>Aprendizaje</1>, para niños",
              paragraph:
                "Obtén una experiencia personalizada. Nuestras lecciones mantienen a los estudiantes comprometidos a través de personajes juguetones y utilizan diferentes estrategias para afianzar su conocimiento.",
              button: "Comenzar a aprender",
            },
            posterMD2: {
              title:
                "Dar <1>Conocimiento</1> a los estudiantes a través del juego",
              paragraph:
                "Con Quinde Lab, puedes personalizar fácilmente el formato de las lecciones para los estudiantes. Crea y sube lecciones sobre temas que involucren a los estudiantes y mejoren sus habilidades.",
              button: "Comenzar a enseñar",
            },
            start_delivery_section: {
              heading:
                "Hoy empezamos a brindar un aprendizaje que impacte a las nuevas generaciones",
              btn_text: "Empecemos",
            },
          },
          contact_us: {
            banner: {
              title: "Contacto",
              paragraph: "Tienes alguna pregunta? Envíanos un mensaje",
            },
            form: {
              first_name: {
                label: "Nombre",
                placeholder: "Tu nombre",
                error: {
                  required: "Este campo es obligatorio",
                },
              },
              last_name: {
                label: "Apellido",
                placeholder: "Tu apellido",
                error: {
                  required: "Este campo es obligatorio",
                },
              },
              email: {
                label: "Correo electrónico",
                placeholder: "Tu dirección de correo electrónico aquí",
                error: {
                  required: "El correo electrónico es obligatorio",
                  invalid: "Ingresa un correo electrónico válido",
                },
              },
              phone: {
                label: "Número telefónico [opcional]",
                placeholder: "[555] 000 000",
                select: {
                  label: "Búsqueda de País",
                },
              },
              subject: {
                label: "Selecciona el asunto?",
                options: {
                  general_query: "Pregunta General",
                  quinde_lab_app: "Quinde LAB app",
                  others: "Otros",
                },
              },
              message: {
                label: "Mensaje",
                placeholder: "escribe tu mensaje aquí",
                error: {
                  required: "El campo de Mensaje no puede quedar vacío",
                },
              },
              action_btn: {
                text: "Enviar Mensaje",
              },
            },
            info: {
              contact_info: "Información de contacto",
              get_in_touch: "Ponte en Contacto con Nosotros",
              phone: "+593 96345 7531",
              email: "info@quinde.org",
              address: "Quito, Ecuador",
            },
            modal: {
              success_heading_msg: "Tu mensaje ha sido enviado exitosamente",
              secondary_message:
                "Nuestro equipo te responderá lo más pronto posible. Gracias!",
            },
          },
          dashboard: {
            sidebar: {
              home: "Hogar",
              courses: "Cursos",
              bookmarks: "Marcadores",
              messages: "Mensajes",
            },
            profile: {
              my_profile: "Mi perfil",
              account_info: "Información de la cuenta",
              logout: "Cerrar sesión",
            },
            course: {
              type: "Curso",
              module: "Módulo",
              description: "Descripción próximamente",
            },
          },
        },
      },
    },
  });

export default i18n;
