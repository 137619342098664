import React from "react";
import useRoutes from "./routes";
import { useRoutes as useRouting } from "react-router-dom";
import "./i18n";
import "./App.css";

const App = () => {
  const routes = useRoutes();
  const routing = useRouting(routes);

  return routing;
};

export default App;
