export const globalStyles = {
  "#root": {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    padding: 0,
    margin: 0,
  },
  body: {
    color: "black.300",
  },
};

export const breakpoints = {
  sm: "480.5px",
  md: "768.5px",
  lg: "1024.5px",
  xl: "1200.5px",
  "2xl": "1444px",
};

export const spacing = {
  xs: "8px",
  sm: "16px",
  md: "24px",
  lg: "32px",
  xl: "40px",
  "2xl": "80px",
};
