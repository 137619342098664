import React, { lazy } from "react";
import { getCachedUser } from "@/utils/auth";

const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));
// *  NOT FOUND PAGE
const NotFound = lazy(() => import("./pages/NotFound"));
// *  LANDING PAGE
const Landing = lazy(() => import("./pages/Landing"));
// *  WELCOME REGISTRATION PAGE
const Welcome = lazy(() => import("./pages/Welcome"));
// * Contact Us
const ContactUs = lazy(() => import("./pages/ContactUs"));
// *  STUDENT DASHBOARD
const StudentDashboard = lazy(() => import("./pages/StudentDashboard"));
// *  STUDENT DASHBOARD Profile
const StudentUserProfile = lazy(() => import("./components/UserProfile"));
// *  STUDENT DASHBOARD COURSES
const Courses = lazy(() => import("./components/Courses"));
// *  STUDENT DASHBOARD Bookmarks
const Bookmarks = lazy(() => import("./components/Bookmarks"));
// *  STUDENT DASHBOARD Messages
const Messages = lazy(() => import("./components/Messages"));
// *  TEACHER DASHBOARD
const TeacherDashboard = lazy(() => import("./pages/TeacherDashboard"));
// *  INTERVENTION FORMS
const Intervention = lazy(() => import("./pages/Intervention"));
// *  SCHEDULE
const Schedule = lazy(() => import("./pages/Schedule"));

const useRoutes = () => {
  const routes = [
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/contact",
      element: <ContactUs />,
    },
    {
      path: "/welcome",
      element: <Welcome />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const user = getCachedUser();

  if (!user) return routes;

  if (user.role === "TEACHER") {
    const teacherRoutes = {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <TeacherDashboard />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "intervention",
          element: <Intervention />,
        },
        {
          path: "schedule",
          element: <Schedule />,
        },
      ],
    };

    routes.push(teacherRoutes);
  }

  if (user.role === "STUDENT") {
    const studentRoutes = {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <StudentDashboard />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "my-profile",
          element: <StudentUserProfile />,
        },
        {
          path: "courses",
          element: <Courses />,
        },
        {
          path: "bookmarks",
          element: <Bookmarks />,
        },
        {
          path: "messages",
          element: <Messages />,
        },
      ],
    };

    routes.push(studentRoutes);
  }

  return routes;
};

export default useRoutes;
